import type { MediaPlatformImage } from '@wix/members-domain-ts';

import { Experiment } from '../../constants/experiments';
import { ToastSkin } from '../../constants/toast';
import { maybeUploadMediaToMediaStore } from '../../services/file-upload';
import type {
  ImageChangeOptions,
  StoreState,
  ThunkDispatch,
  ThunkExtra,
  ThunkWithArgs,
} from '../../types';
import { getSetViewedMemberPictureAction } from '../actions';
import {
  clearInitialDataCache,
  emitProfileEditBIEvents,
  scheduleViewedMemberSync,
  showToast,
  toggleIsProfileSaving,
  toggleProfileSavedNotification,
} from './common';

interface UpdateProfilePictureOptions {
  dispatch: ThunkDispatch;
  state: StoreState;
  options: ImageChangeOptions;
  extra: ThunkExtra;
}

const getUpdatedFields = async (
  { name, imageUrl }: ImageChangeOptions,
  services: ThunkExtra,
) => {
  const editPicture = { name, file: imageUrl };
  return maybeUploadMediaToMediaStore({ services, editPicture });
};

const updateProfilePicture = async ({
  dispatch,
  state: { users },
  options,
  extra,
}: UpdateProfilePictureOptions) => {
  const { uid } = users.viewed;
  const { membersService } = extra;

  const updatedFields = await getUpdatedFields(options, extra);
  const picture = updatedFields.picture as MediaPlatformImage;

  await membersService.partialMemberUpdate(uid, updatedFields);

  dispatch(getSetViewedMemberPictureAction(picture));
  scheduleViewedMemberSync(extra);

  return updatedFields;
};

export const setMemberPicture: ThunkWithArgs<ImageChangeOptions> =
  (options) => async (dispatch, getState, extra) => {
    const { flowAPI, errorHandlerService } = extra;
    const state = getState();

    const showNewNotifications = flowAPI.experiments.enabled(
      Experiment.ShowNewNotificationsContent,
    );

    const updateOptions = { dispatch, state, options, extra };
    toggleIsProfileSaving(dispatch, extra);

    try {
      const updatedFields = await updateProfilePicture(updateOptions);

      emitProfileEditBIEvents(state, updatedFields, extra);
      clearInitialDataCache(state, extra);

      if (showNewNotifications) {
        showToast(dispatch, {
          message: flowAPI.translations.t(
            'profile-widget.change-profile-image.success',
          ),
        });
      } else {
        toggleProfileSavedNotification(extra);
      }
    } catch (error) {
      if (showNewNotifications) {
        const { message } = errorHandlerService.extractErrorData(error);

        showToast(dispatch, {
          message,
          skin: ToastSkin.error,
        });
      }
    } finally {
      toggleIsProfileSaving(dispatch, extra);
    }
  };
